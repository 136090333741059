import React, { useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import LoadingButton from '../../components/LoadingButton';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import { fetchUser, checkAdmin } from '../../store/user/actions';
import { FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from '../../store/user/constants';
import { queueNotification } from '../../store/notifications/actions';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginPage = () => {

  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({ email: '', password: '' });
  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleLoginSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);

    if (formState.email.length === 0 || formState.password.length === 0) {
      dispatch(queueNotification({ type: 'ERROR', message: 'You must enter an email and password.' }));
      setSubmitting(false);
      return;
    }

    const result = await dispatch(fetchUser(formState.email, formState.password));

    if (result.type === FETCH_USER_SUCCESS) {
      const token = await result.payload.user.getIdToken(true);
      localStorage.setItem("token", token);
      await dispatch(checkAdmin());
      history.push('/');
    }

    if (result.type === FETCH_USER_FAILURE) {
      const errorMessage = typeof result.errors === 'string' ? result.errors : 'Login failed!';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      setSubmitting(false);
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} >
        <Avatar className={classes.avatar} src="/assets/img/logo.png">
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleLoginSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleInputChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleInputChange}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={submitting}
            disabled={submitting}
            className={classes.submit}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default LoginPage;