import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  "apiKey": "AIzaSyCklhccUUOHLUpmjs5NLCEqq4NGzkH0gKQ",
  "authDomain": "remedium-6031e.firebaseapp.com",
  "databaseURL": "https://remedium-6031e.firebaseio.com",
  "messagingSenderId": "941884908786",
  "projectId": "remedium-6031e",
  "storageBucket": "remedium-6031e.appspot.com"
};

export const firevibe = firebase.initializeApp(firebaseConfig);