import React from 'react';
import { render } from 'react-dom';

import App from './App';

const mountApp = document.getElementById('root');
render(<App />, mountApp);

if (process.env.NODE_ENV === 'development') {
  module.hot.accept();
}
