import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { firevibe } from '../../../firebase/firebase';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import NotificationCenter from '../../NotificationCenter';
import getUser from '../../../util/user';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingLeft: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  ButtonIconSpace: {
    marginRight: theme.spacing(1),
  },
  buttonText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}));

const CustomerLayout = ({ children }) => {
  const history = useHistory();

  const classes = useStyles();

  const handleLogout = useCallback(async (e) => {
    e.preventDefault();
    await firevibe.auth().signOut()
    localStorage.setItem("token", "");
    history.push('/login');
  })

  useEffect(() => {
    const authenticate = async () => {
      const promises = [];
      promises.push(
        (
          async () => {
            const user = await getUser();
            if (user === null) {
              history.replace('/login');
            } else {
              const idToken = await user.getIdToken(true);
              localStorage.setItem("token", idToken);
            }
          }
        )()
      )

      await Promise.all(promises);
    }

    authenticate();
  }, []);

  const openPage = (path) => {
    history.push(path);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Firevibe
          </Typography>
          <Button color="inherit" onClick={() => openPage("/")}>
            <PhotoLibraryIcon className={classes.ButtonIconSpace} />
            <Typography component="h6" variant="h6" color="inherit" noWrap className={classes.buttonText}>
              Manage Projects
            </Typography>
          </Button>
          <Button color="inherit" onClick={() => openPage("/uploadproject")}>
            <AddPhotoAlternateIcon className={classes.ButtonIconSpace} />
            <Typography component="h6" variant="h6" color="inherit" noWrap className={classes.buttonText}>
              Upload a Project
            </Typography>
          </Button>
          <Button color="inherit" onClick={() => openPage("/account")}>
            <SettingsIcon className={classes.ButtonIconSpace} />
            <Typography component="h6" variant="h6" color="inherit" noWrap className={classes.buttonText}>
              Account Settings
            </Typography>
          </Button>
          <Button color="inherit" onClick={handleLogout}>
            <ExitToAppIcon className={classes.ButtonIconSpace} />
            <Typography component="h6" variant="h6" color="inherit" noWrap className={classes.buttonText}>
              Log out
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
          <NotificationCenter />
        </Container>
      </main>
    </div>
  );
};

CustomerLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired
};

export default CustomerLayout;
