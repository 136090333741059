import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { getUserInfo, updateUserInfo } from '../../store/user/actions';
import { queueNotification } from '../../store/notifications/actions';
import { GET_USER_INFO_FAILURE, UPDATE_USER_INFO_FAILURE, UPDATE_USER_INFO_SUCCESS } from '../../store/user/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
  cardRoot: {
    minWidth: 400,
  },
  cardMargin: {
    marginTop: theme.spacing(2)
  }
}));

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: "#424770",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    })
  );

  return options;
};

const CAccount = () => {
 
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [editcard, setEditCard] = useState(true);
  const user = useSelector((state) => state.user);

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  useEffect(() => {

    const getUser = async () => {
      setLoading(() => true);
      const userInfo = await dispatch(getUserInfo());

      setLoading(() => false);

      if (userInfo.type === GET_USER_INFO_FAILURE) {
        dispatch(queueNotification({ type: 'ERROR', message: userInfo.errors }));
      }
    }

    getUser()
  }, []);

  const handleCardUpdate = useCallback(async (e) => {

    if (!editcard) {
      setEditCard(() => true)
    } else {
      if (!stripe || !elements) {
        return;
      }

      setLoading(() => true);

      const cardElement = elements.getElement(CardElement);

      try {

        const { token } = await stripe.createToken(cardElement);

        if (token === undefined) return;
        
        var reqJson = {
          token: token.id,
          ccnumber: `************${token.card.last4}`,
        }

        const result = await dispatch(updateUserInfo(reqJson));

        setLoading(false);

        if (result.type === UPDATE_USER_INFO_SUCCESS ) {
          dispatch(queueNotification({ type: 'SUCCESS', message: 'Successfully updated!' }));
          return;
        }

        if (result.type === UPDATE_USER_INFO_FAILURE ) {
          const errorMessage = typeof result.errors === 'string' ? err : 'Sorry, Failed to update your card. Please contact admin.';
          dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
          return;
        }
      } catch (err) {
        setLoading(false);
        const errorMessage = typeof err === 'string' ? err : 'Please input correct card number';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        return;
      }
    }
  })

  return (
    <div className={classes.root}>
      <div className={classes.placeholder}>
      { loading && <CircularProgress /> }
      </div>
      {/* {!loading && ( */}
        <Card className={classes.cardRoot}>
          <CardContent>
            <Typography align="center">
              Full name: {user?.data?.username}
            </Typography>
            <Typography align="center">
              Email name: {user?.data?.email}
            </Typography>
            <Typography align="center">
              Phone Number: {user?.data?.phoneNumber}
            </Typography>
            <Typography align="center">
              Card Number: {user?.data?.ccnumber}
            </Typography>
            {
              editcard && (
                <React.Fragment>
                  <Typography className={classes.cardMargin} component="h1" variant="h5" align="center" paragraph>
                    Payment Authorization
                  </Typography>
                  <CardElement options={options}> </CardElement>
                </React.Fragment>
              )
            }
            
          </CardContent>
          <CardActions>
            <Button fullWidth color="primary" onClick={handleCardUpdate}> { editcard ? "Update Card" : "Edit Card" } </Button>
          </CardActions>
        </Card>
      {/* )} */}
    </div>
  );
};
export default CAccount;
