import API from '../../util/api.js';

import { FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILURE, 
  UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE,
  FETCH_COMPLETE_ORDERS_REQUEST, FETCH_COMPLETE_ORDERS_SUCCESS, FETCH_COMPLETE_ORDERS_FAILURE,
  VOID_ORDER_REQUEST, VOID_ORDER_SUCCESS, VOID_ORDER_FAILURE } from './constants';

const fetchOrdersRequest = () => ({ type: FETCH_ORDERS_REQUEST });
const fetchOrdersSuccess = (payload) => ({ type: FETCH_ORDERS_SUCCESS, payload });
const fetchOrdersFailure = (errors) => ({ type: FETCH_ORDERS_FAILURE, errors });

export const fetchOrders = () => async (dispatch) => {
  dispatch(fetchOrdersRequest());
  try {
    
    const result = await API.get('/project/open', {
      headers: {
        'Authorization': `${localStorage.getItem("token")}` 
      }
    });
    if (!result.data.success) {
      return dispatch(fetchOrdersFailure(result.data.data));
    } else {
      return dispatch(fetchOrdersSuccess(result.data.data));
    }
  } catch (e) {
    return dispatch(fetchOrdersFailure(e.message));
  }
};

const updateOrderRequest = () => ({ type: UPDATE_ORDER_REQUEST });
const updateOrderSuccess = (payload) => ({ type: UPDATE_ORDER_SUCCESS, payload });
const updateOrderFailure = (errors) => ({ type: UPDATE_ORDER_FAILURE, errors });

export const updateOrder = (updatedProject) => async (dispatch) => {
  dispatch(updateOrderRequest());
  try {
    const result = await API.post('/project/update', updatedProject, {
      headers: {
        'Authorization': `${localStorage.getItem("token")}` 
      }
    });
    return dispatch(updateOrderSuccess( { msg: result.data.data, id: updatedProject.id } ));
  } catch (e) {
    return dispatch(updateOrderFailure(e.response.data.data.raw.message));
  }
}

const voidOrderRequest = () => ({ type: VOID_ORDER_REQUEST });
const voidOrderSuccess = (payload) => ({ type: VOID_ORDER_SUCCESS, payload });
const voidOrderFailure = (errors) => ({ type: VOID_ORDER_FAILURE, errors });

export const voidOrder = (updatedProject) => async (dispatch) => {
  dispatch(voidOrderRequest());
  try {
    const result = await API.post('/project/void', updatedProject, {
      headers: {
        'Authorization': `${localStorage.getItem("token")}` 
      }
    });
    return dispatch(voidOrderSuccess( { msg: result.data.data, id: updatedProject.id } ));
  } catch (e) {
    return dispatch(voidOrderFailure(e.message));
  }
}

const fetchCompleteOrdersRequest = () => ({ type: FETCH_COMPLETE_ORDERS_REQUEST });
const fetchCompleteOrdersSuccess = (payload) => ({ type: FETCH_COMPLETE_ORDERS_SUCCESS, payload });
const fetchCompleteOrdersFailure = (errors) => ({ type: FETCH_COMPLETE_ORDERS_FAILURE, errors });

export const fetchCompleteOrders = () => async (dispatch) => {
  dispatch(fetchCompleteOrdersRequest());
  try {
    
    const result = await API.get('/project/completed', {
      headers: {
        'Authorization': `${localStorage.getItem("token")}` 
      }
    });
    if (!result.data.success) {
      return dispatch(fetchCompleteOrdersFailure(result.data.data));
    } else {
      return dispatch(fetchCompleteOrdersSuccess(result.data.data));
    }
  } catch (e) {
    return dispatch(fetchCompleteOrdersFailure(e.message));
  }
};