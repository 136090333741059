import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Dropbox from 'dropbox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { queueNotification } from '../../store/notifications/actions';

import { fetchPhotos,
  fetchPhotoDeleteRequest, 
  fetchPhotosReset,
  photoUploadRequest,
  photoUploadProgress,
  photoUploadSuccess,
  photoUploadFailed } 
from '../../store/uploadFiles/actions';

import { createProject } from '../../store/projects/actions';

import { CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE } from '../../store/projects/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  gridRoot: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
  paper: {
    marginTop: '24px',
  },
  table: {
    marginTop: '24px',
  },
  nameCell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  }
}));

const UPLOAD_STATES = {
  0: "Not Uploaded",
  1: "Uploading",
  2: "Uploaded",
  3: "Upload Failed"
};

const ACCESS_TOKEN = "ZJ4mrGK4yNAAAAAAAAAAHH3rcxjD-rS1JqzVUtzniZ3BE_abRYyVICv1xyasQOYd"
const UPLOAD_FILE_SIZE_LIMIT = 0.4 * 1024 * 1024;
const DROPBOX_ROOT_PATH = "/Fire Vibe Server/App Uploads Master/"

const dbx = new Dropbox.Dropbox({ accessToken: ACCESS_TOKEN, fetch: fetch });

const CUploadProject = () => {
 
  const dispatch = useDispatch();
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const uploadFiles = useSelector((state) => state.uploadFiles);

  const [formState, setFormState] = useState({ projectName: '', emailAddress: '' });
  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));

  var uploadIndex = 0;

  useEffect(() => {
  }, []);

  const fileSelectedHandler = event => {
    dispatch(fetchPhotos(event.target.files));
  }

  const handleChooseFiles = () => {
    document.getElementById("files").click()
  }

  const handleResetFiles = () => {
    dispatch(fetchPhotosReset());
  }

  const filesUploadHandler = useCallback(async () => {

    if (formState.projectName.trim() === "") {
      dispatch(queueNotification({ type: 'ERROR', message: 'Please input project name' }));
      return;
    }

    if (formState.emailAddress.trim() === "") {
      setFormState({ ...formState, emailAddress: localStorage.getItem("email")});
      return 
    }

    uploadIndex = 0;
    uploadFilesToDropbox();
  })

  const uploadFilesToDropbox = useCallback(async () => {

    try {
      
      if (uploadIndex > uploadFiles.needUploadFiles.length - 1) {

        const reqJSON = {
          projectName: formState.projectName,
          emailAddress: formState.emailAddress,
          photoCount: 0,
          videoCount: 0,
          dropboxURL: "",
          manualUpload: true
        }
        const response = await dispatch(createProject(reqJSON))

        if (response.type == CREATE_PROJECT_SUCCESS) {
          dispatch(queueNotification({ type: 'SUCCESS', message: 'Successfully uploaded!' }));
        }

        if (response.type == CREATE_PROJECT_FAILURE) {
          dispatch(queueNotification({ type: 'ERROR', message: 'Your files are uploaded successfully, Failed to update the server. Please contact admin.' }));
        }

        setUploading(false);
        return;
      }

      setUploading(true)

      const file = uploadFiles.needUploadFiles[uploadIndex];

      const dropPath = `${DROPBOX_ROOT_PATH}Manual/${formState.projectName}_${formState.emailAddress}/${file.name}`;

      dispatch(photoUploadRequest(uploadIndex))

      if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
        const response = await dbx.filesUpload({path: dropPath, contents: file})
        dispatch(photoUploadSuccess(uploadIndex))
      } else {

        const maxBlob = 0.3 * 1000 * 1000;
        var workItems = [];

        var offset = 0;

        while (offset < file.size) {
          var chunkSize = Math.min(maxBlob, file.size - offset);
          workItems.push(file.slice(offset, offset + chunkSize));
          offset += chunkSize;
        }

        const task = workItems.reduce((acc, blob, idx, items) => {
          if (idx == 0) {
            // Starting multipart upload of file
            return acc.then(function() {
              return dbx.filesUploadSessionStart({ close: false, contents: blob})
                        .then(response => response.session_id)
            });          
          } else if (idx < items.length-1) {  
            // Append part to the upload session
            return acc.then(function(sessionId) {
             var cursor = { session_id: sessionId, offset: idx * maxBlob };
             dispatch(photoUploadProgress((idx * maxBlob / file.size * 100).toFixed(2)));
             return dbx.filesUploadSessionAppendV2({ cursor: cursor, close: false, contents: blob }).then(() => sessionId); 
            });
          } else {
            // Last chunk of data, close session
            return acc.then(function(sessionId) {
              var cursor = { session_id: sessionId, offset: file.size - blob.size };
              var commit = { path: dropPath, mode: 'add', autorename: true, mute: false };              
              dispatch(photoUploadSuccess(uploadIndex))
              return dbx.filesUploadSessionFinish({ cursor: cursor, commit: commit, contents: blob });           
            });
          }          
        }, Promise.resolve());

        const response = await task
      }

      uploadIndex += 1;
      uploadFilesToDropbox();
    } catch (err) {
      dispatch(photoUploadFailed(uploadIndex))
    }
  });

  const removeImage = (index) => {
    dispatch(fetchPhotoDeleteRequest(index));
  }

  const renderTable = () => {

    var rows = [];

    if (uploadFiles.needUploadFiles !== null) {
      Array.prototype.forEach.call(uploadFiles.needUploadFiles, (element, index) => {
        rows.push({no: index, name: element.name, size: element.size, status: UPLOAD_STATES[uploadFiles.imageStatus[index]], progress: uploadFiles.uploadingProgress})
      })
    }
    return (
      rows.length > 0 ? (
        <React.Fragment>
          <Grid className={classes.gridRoot} container spacing={2}>
            <Grid item xs={6} align="center">
              <Button variant="contained" color="primary" onClick={filesUploadHandler} disabled={uploading}>
                Upload
              </Button>
            </Grid>
            <Grid item xs={6} align="center">
              <Button variant="contained" color="primary" onClick={handleResetFiles} disabled={uploading}>
                Reset Files
              </Button>
            </Grid>
          </Grid>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center"> No </TableCell>
                <TableCell align="center" className={classes.nameCell}> Name </TableCell>
                <TableCell align="center"> Size </TableCell>
                <TableCell align="center"> Status </TableCell>
                <TableCell align="center"> Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { 
              rows.map( row => (
                <TableRow key={row.name}>
                  <TableCell align="center"> { row.no + 1 } </TableCell>
                  <TableCell align="center" className={classes.nameCell}> { row.name } </TableCell>
                  <TableCell align="center"> { (row.size / 1024 / 1024).toFixed(2) } MB </TableCell>
                  <TableCell align="center"> { row.status == "Uploading" ? row.progress : row.status} </TableCell>
                  <TableCell align="center"> 
                    <Fab aria-label="Delete" disabled={uploading} onClick={ () => removeImage(row.no)}>
                      <DeleteIcon />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))
            }
            </TableBody>
          </Table>
        </React.Fragment>
      )
      : 
      (
        <Typography component="h1" variant="h5" align="center" paragraph> No Files are selected. Please choose files to upload. </Typography>
      )
    )
  }

  return (
    <div className={classes.root}>
      <input 
          type="file"
          multiple
          hidden
          id="files"
          onChange={fileSelectedHandler}
        />

      <Grid className={classes.gridRoot} container spacing={2}>
        <Grid item xs={12} lg={4} md={4} align="center">
          <Grid className={classes.paper} container spacing={2}>
            <Grid item xs={12} align="center">
              <TextField
                autoFocus
                margin="dense"
                name="projectName"
                label="Project Name"
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} align="center">
              <TextField
                margin="dense"
                name="emailAddress"
                label="Email Address"
                defaultValue={localStorage.getItem("email")}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Button color="inherit" color="primary" variant="contained" onClick={handleChooseFiles} disabled={uploading}>
                { uploadFiles.needUploadFiles === null ? "Choose Files" : "Choose More Files" }
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} md={8} align="center">
          {renderTable()}
        </Grid>
      </Grid>
    </div>
  );
};
export default CUploadProject;
