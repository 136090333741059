import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  CHECK_ADMIN_REQUEST,
  CHECK_ADMIN_SUCCESS,
  CHECK_NOADMIN_SUCCESS,
  CHECK_ADMIN_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE
} from './constants';

const initialState = { data: {}, isAdmin: false, loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return { ...state, loading: true };
    case FETCH_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_USER_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CREATE_USER_REQUEST:
      return { ...state, loading: true };
    case CREATE_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case CREATE_USER_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case GET_USER_INFO_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case GET_USER_INFO_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CHECK_ADMIN_REQUEST:
      return { ...state, loading: true };
    case CHECK_NOADMIN_SUCCESS:
      return { ...state, loading: false, isAdmin: false, errors: [] }    
    case CHECK_ADMIN_SUCCESS:
      return { ...state, loading: false, isAdmin: true, errors: [] };
    case CHECK_ADMIN_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case UPDATE_USER_INFO_REQUEST:
      return { ...state, loading: true };
    case UPDATE_USER_INFO_SUCCESS:
      return { ...state, loading: false, errors: [] };
    case UPDATE_USER_INFO_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;