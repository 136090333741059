import {
  PHOTOS_FETCHED,
  PHOTOS_RESET,
  PHOTOS_DELETE_REQUEST,
  PHOTO_UPLOAD_REQUEST,
  PHOTO_UPLOAD_SUCCESS,
  PHOTO_UPLOAD_PROGRESS,
  PHOTO_UPLOAD_FAILED,
  IAMGE_NOT_UPLOADED,
  IMAGE_UPLOADING_PROGRESS,
  IMAGE_UPLOADED,
  IMAGE_UPLOAD_FAILED,
  PHOTO_ALL_UPLOADED
} from './constants';

const initialState = { needUploadFiles: null, uploadingProgress: '0%', isNeedNextUpload: false, imageStatus: [] };

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case PHOTOS_FETCHED:
      var uploadFiles = state.needUploadFiles;
      var status = state.imageStatus;

      if (uploadFiles === null) {
        uploadFiles = action.payload;
      } else {
        uploadFiles = Array.prototype.slice.call(uploadFiles).concat(Array.prototype.slice.call(action.payload));
      }

      if (action.payload !== null && action.payload.length > 0) {
        Array.prototype.forEach.call(action.payload, (element) => {
          status.push(IAMGE_NOT_UPLOADED)
        })
      }
      return { ...state, needUploadFiles: uploadFiles, uploadingProgress: '0%', imageStatus: status };
    case PHOTOS_RESET:
      return { needUploadFiles: null, uploadingProgress: '0%', isNeedNextUpload: false, imageStatus: [] };
    case PHOTOS_DELETE_REQUEST:

      var uploadFiles = [];
      var status = state.imageStatus;

      var index = 0;
      Array.prototype.forEach.call(state.needUploadFiles, (element) => {
        if (index !== action.index) {
          uploadFiles.push(element);
        }
        index += 1;
      })

      status.splice(action.index, 1);

      return { ...state, needUploadFiles: uploadFiles.length === 0 ? null : uploadFiles, imageStatus: status };
    case PHOTO_UPLOAD_REQUEST: 
      var status = state.imageStatus;
      status[action.index] = IMAGE_UPLOADING_PROGRESS;
      return { ...state, imageStatus: status };
    case PHOTO_UPLOAD_PROGRESS:
      const percent = `${action.percents}%`;
      return { ...state, uploadingProgress: percent};
      break;
    case PHOTO_UPLOAD_SUCCESS: 
      var status = state.imageStatus;
      status[action.index] = IMAGE_UPLOADED;
      return { ...state, imageStatus: status };
    case PHOTO_UPLOAD_FAILED:
      var status = state.imageStatus;
      status[action.index] = IMAGE_UPLOAD_FAILED;
      return { ...state, imageStatus: status };
    case PHOTO_ALL_UPLOADED:
      break;
    default:
      return state;
  }
};

export default reducer;