import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { queueNotification } from '../../store/notifications/actions';

import { fetchCompleteOrders } from '../../store/orders/actions';

import { FETCH_COMPLETE_ORDERS_SUCCESS, FETCH_COMPLETE_ORDERS_FAILURE } from '../../store/orders/constants';

import MaterialTable from 'material-table';
import moment from 'moment';

import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
  userTable: {
    width: '100%'
  }
}));

const CompleteOrderPage = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [state, setState] = React.useState({
    columns: [
      { title: 'Project Name', field: 'projectname' },
      { title: 'Email', field: 'email' },
      { title: 'Project Link', field: 'projectlink' },
      { title: 'Ordered At', field: 'orderedat' },
    ],
    headers: [
      { label: 'Project Name', key: 'projectname' },
      { label: 'Email', key: 'email' },
      { label: 'Project Link', key: 'projectlink' },
      { label: 'Ordered At', key: 'orderedat' },
    ],
    data: [
    ],
  });
 
  useEffect(() => {
    const completeOrders = async () => {
      setLoading(() => true);
      const fetchCompleteOrdersResult = await dispatch(fetchCompleteOrders());

      setLoading(() => false);

      if (fetchCompleteOrdersResult.type === FETCH_COMPLETE_ORDERS_FAILURE) {
        const errorMessage = typeof fetchCompleteOrdersResult.errors === 'string' ? err : 'Failed to fetch all the users.';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      }
      if (fetchCompleteOrdersResult.type === FETCH_COMPLETE_ORDERS_SUCCESS) {
        setState((prevState) => {
          const data = [];
          fetchCompleteOrdersResult.payload.forEach(
            (project) => {
              const orderedAt = moment.unix(project.createdAt._seconds).format('YYYY/MM/DD');
              data.push({
                projectname: project.projectName, 
                email: project.emailAddress,
                projectlink: project.dropboxURL,
                orderedat: orderedAt
              });
          })
          return { ...prevState, data };
        });
      }
    }

    completeOrders()
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.placeholder}>
        { loading && <CircularProgress /> }
      </div>
      
      { !loading && (
        <div className={classes.userTable}>
          <MaterialTable
            className={classes.userTable}
            title="Complete Orders Table"
            columns={state.columns}
            data={state.data}
          />
          <CSVLink headers={state.headers} data={state.data} filename={"complete-orders.csv"}>Download Complete Orders</CSVLink>
        </div>
      )}
    </div>
  );
};
export default CompleteOrderPage;
