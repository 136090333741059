import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ReportPage = () => {
 
  // Initialize or tear down shaka player
  useEffect(() => {
  }, []);

  return (
    <div>
      Report Page
    </div>
  );
};
export default ReportPage;
