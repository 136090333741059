import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'react-moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { fetchMyProject } from '../../store/projects/actions';
import { queueNotification } from '../../store/notifications/actions';

import { FETCH_MYPROJECT_FAILURE, FETCH_MYPROJECT_SUCCESS } from '../../store/projects/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CDashboard = () => {
 
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const projects = useSelector((state) => state.projects);
 
  useEffect(() => {

    const fetchProject = async () => {
      setLoading(() => true);
      const fetchProjectResult = await dispatch(fetchMyProject());

      setLoading(() => false);

      if (fetchProjectResult.type === FETCH_MYPROJECT_SUCCESS) {
        dispatch(queueNotification({ type: 'SUCCESS', message: 'Your projects loaded' }));
      }

      if (fetchProjectResult.type === FETCH_MYPROJECT_FAILURE) {
        dispatch(queueNotification({ type: 'ERROR', message: fetchProjectResult.errors }));
      }
    }

    fetchProject()
  }, []);

  const handleOpenProject = (dropboxURL) => {
    if (dropboxURL.trim() === "") {
      return;
    }
    window.open(dropboxURL, "_blank");
  }

  const renderProjects = () => {

    if (Object.keys(projects?.data).length === 0 && projects?.data.constructor === Object) {
      return;
    }

    return projects?.data.map((row, index) => (
      <StyledTableRow key={index} onClick={() => handleOpenProject(row.dropboxURL)}>
        <StyledTableCell component="th" scope="row">
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center">{row.projectName}</StyledTableCell>
        <StyledTableCell align="center">{row.photoCount}</StyledTableCell>
        <StyledTableCell align="center">{row.videoCount}</StyledTableCell>
        <StyledTableCell align="center"><Moment unix format="YYYY/MM/DD">{row.createdAt._seconds}</Moment></StyledTableCell>
        <StyledTableCell align="center">{row.dropboxURL === "" ? "In Progress" : "Completed"}</StyledTableCell>
      </StyledTableRow>
    ))
  }

  return (
    <div className={classes.root}>
      <div className={classes.placeholder}>
      { loading && <CircularProgress /> }
      </div>
      {!loading && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell align="center">Project Name</TableCell>
                <TableCell align="center">Photo Count</TableCell>
                <TableCell align="center">Video Count</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderProjects()}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
export default CDashboard;
