import API from '../../util/api.js';

import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE
} from './constants';

const getDashboardRequest = () => ({ type: GET_DASHBOARD_REQUEST });
const getDashboardSuccess = (payload) => ({ type: GET_DASHBOARD_SUCCESS, payload });
const getDashboardFailure = (errors) => ({ type: GET_DASHBOARD_FAILURE, errors });

export const getDashboard = () => async (dispatch) => {
  dispatch(getDashboardRequest());
  try {
    const response = await API.get('/admin/dashboard', {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    });
    return dispatch(getDashboardSuccess(response.data.body));
  } catch (e) {
    return dispatch(getDashboardFailure(e.message));
  }
};