import API from '../../util/api.js';

import {
  FETCH_MYPROJECT_REQUEST,
  FETCH_MYPROJECT_SUCCESS,
  FETCH_MYPROJECT_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE
} from './constants';

const fetchMyProjectRequest = () => ({ type: FETCH_MYPROJECT_REQUEST });
const fetchMyProjectSuccess = (payload) => ({ type: FETCH_MYPROJECT_SUCCESS, payload });
const fetchMyProjectFailure = (errors) => ({ type: FETCH_MYPROJECT_FAILURE, errors });

export const fetchMyProject = () => async (dispatch) => {
  dispatch(fetchMyProjectRequest());
  try {
    const result = await API.get('/project/customer/myproject', {
      headers: {
        'Authorization': `${localStorage.getItem("token")}` 
      }
    });
    if (!result.data.success) {
      return dispatch(fetchMyProjectFailure(result.data.data));
    } else {
      return dispatch(fetchMyProjectSuccess(result.data.data));
    }
  } catch (e) {
    return dispatch(fetchMyProjectFailure(e.message));
  }
};

const createProjectRequest = () => ({ type: CREATE_PROJECT_REQUEST });
const createProjectSuccess = () => ({ type: CREATE_PROJECT_SUCCESS });
const createProjectFailure = (errors) => ({ type: CREATE_PROJECT_FAILURE, errors });

export const createProject = (reqJSON) => async (dispatch) => {

  dispatch(createProjectRequest());
  try {
    const result = await API.post('/project', reqJSON, {
      headers: {
        'Authorization': `${localStorage.getItem("token")}` 
      }
    });

    if (result.data.success) {
      return dispatch(createProjectSuccess(result.data.data));
    }

    return dispatch(createProjectFailure(result.data.data));
  } catch (e) {
    return dispatch(createProjectFailure(e.message));
  }
}