import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

// Reducers
import reducer from './reducer';

// Admin Pages
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/Login';
import SignupPage from './pages/Signup';
import OpenOrderPage from './pages/Order';
import CompleteOrderPage from './pages/CompleteOrder';
import CustomerPage from './pages/Customer';
import ReportPage from './pages/Report';

// Customer Pages
import CDashboard from './cpages/Dashboard';
import CUploadProject from './cpages/UploadProject';
import CAccount from './cpages/Account';

import RouteWithLayout from './components/RouteWithLayout';

import PublicLayout from './components/Layouts/Public';
import MainLayout from './components/Layouts/Main';
import CustomerLayout from './components/Layouts/Customer';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, {}, composeEnhancers(applyMiddleware(thunk)));

const stripePromise = loadStripe("pk_live_KzdHe5XMOC6rnX6vBiopzQRz");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F87E54',
      contrastText: '#fff',
    }
  },
});

const App = () => (
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            {/* Admin Routes */}
            <RouteWithLayout exact path="/admin" component={Dashboard} layout={MainLayout} />
            <RouteWithLayout exact path="/admin/openorders" component={OpenOrderPage} layout={MainLayout} />
            <RouteWithLayout exact path="/admin/completeorders" component={CompleteOrderPage} layout={MainLayout} />
            <RouteWithLayout exact path="/admin/customers" component={CustomerPage} layout={MainLayout} />
            <RouteWithLayout exact path="/admin/reports" component={ReportPage} layout={MainLayout} />

            {/* Customer Routes */}
            <RouteWithLayout exact path="/" component={CDashboard} layout={CustomerLayout} />
            <RouteWithLayout exact path="/uploadproject" component={CUploadProject} layout={CustomerLayout} />
            <RouteWithLayout exact path="/account" component={CAccount} layout={CustomerLayout} />

            <RouteWithLayout exact path="/login" component={LoginPage} layout={PublicLayout} />
            <RouteWithLayout exact path="/signup" component={SignupPage} layout={PublicLayout} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </Elements>
  </Provider>
);

export default App;
