import { combineReducers } from 'redux';

import user from './store/user/reducer';
import allUser from './store/alluser/reducer';
import notifications from './store/notifications/reducer';
import orders from './store/orders/reducer';
import projects from './store/projects/reducer';
import uploadFiles from './store/uploadFiles/reducer';
import dashboard from './store/dashboard/reducer';

const rootReducer = combineReducers({
  user,
  allUser,
  notifications,
  orders,
  projects,
  uploadFiles,
  dashboard
});

export default rootReducer;
