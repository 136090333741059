import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { openNotification, closeNotification, dequeueNotification } from '../../store/notifications/actions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NotificationCenter = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    if (notifications.data.length > 0) {
      dispatch(openNotification());
    }
  }, [notifications.data]);

  useEffect(() => {
    if (notifications.visible) {
      setTimeout(() => {
        dispatch(closeNotification());
        setTimeout(() => {
          dispatch(dequeueNotification());
        }, 1000);
      }, 5000);
    }
  }, [notifications.visible]);

  const currentNotification = notifications.data[0];

  return (
    <Snackbar open={notifications.visible} autoHideDuration={5000}>
      <Alert severity={currentNotification?.type?.toLowerCase()}>
        {currentNotification?.message}
      </Alert>
    </Snackbar>
  );
};

export default NotificationCenter;