export const PHOTOS_FETCHED = 'PHOTOS_FETCHED';
export const PHOTOS_RESET = 'PHOTOS_RESET';
export const PHOTOS_DELETE_REQUEST = 'PHOTOS_DELETE_REQUEST';

export const PHOTO_UPLOAD_REQUEST = 'PHOTO_UPLOAD_REQUEST';
export const PHOTO_UPLOAD_PROGRESS = 'PHOTO_UPLOAD_PROGRESS';
export const PHOTO_UPLOAD_SUCCESS = 'PHOTO_UPLOAD_SUCCESS';
export const PHOTO_UPLOAD_FAILED = 'PHOTO_UPLOAD_FAILED';

export const PHOTO_ALL_UPLOADED = 'PHOTO_ALL_UPLOADED';

export const IAMGE_NOT_UPLOADED = 0
export const IMAGE_UPLOADING_PROGRESS = 1
export const IMAGE_UPLOADED = 2
export const IMAGE_UPLOAD_FAILED = 3