import React, { useCallback, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import * as EmailValidator from 'email-validator';

import { createUser } from '../../store/user/actions';
import { CREATE_USER_FAILURE } from '../../store/user/constants';
import { queueNotification } from '../../store/notifications/actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    margin: theme.spacing(2),
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  fullWidth: {
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  commentContainer: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    flexFlow: 'column',
  },
  completeContainer: {
    display: 'flex',
    marginTop: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  mainContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  formErrors: {
    color: 'red',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  }
}));

const getSteps = () => {
  return ['Step 1', 'Step 2', 'Step 3'];
}

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: "#424770",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    })
  );

  return options;
};

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const SignUpPage = () => {

  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({ firstName: '', lastName: '', email: '', password: '', confirmPassword: '', discountCode: '', phoneNumber: '', });
  const [formErrorState, setFormErrorState] = useState({ errorFirstName: '', errorLastName: '', errorEmail: '', errorPassword: '', errorPhoneNumber: '', });
  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));
  const dispatch = useDispatch();
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleNext = useCallback(async (e) => {

    if (submitting) return;

    if (activeStep == 0) {

      var hasError = false;
      
      if (formState.firstName.trim().length == 0) {
        hasError = true;
        setFormErrorState(formErrorState => ({ ...formErrorState, errorFirstName: '*Please input the first name.'}))
      }
      if (formState.lastName.trim().length == 0) {
        hasError = true;
        setFormErrorState(formErrorState => ({ ...formErrorState, errorLastName: '*Please input the last name.'}))
      }

      if (formState.email.trim().length > -1) {
        hasError = !EmailValidator.validate(formState.email);
        const emailValid =  !hasError? '' : "*Please input the valid email."
        setFormErrorState(formErrorState => ({ ...formErrorState, email: emailValid}))
      }

      if (formState.phoneNumber == '') {
        hasError = true;
        setFormErrorState(formErrorState => ({ ...formErrorState, phoneNumber: '*Please input the valid phone number.'}))
      }

      if (formState.password.trim().length == 0 || formState.password.trim().length < 6) {
        hasError = true;
        setFormErrorState(formErrorState => ({ ...formErrorState, password: '*Password must be longer than 6.'}))
      }

      if (formState.password.trim() != formState.confirmPassword.trim()) {
        hasError = true;
        setFormErrorState(formErrorState => ({ ...formErrorState, password: '*Password does not match.'}))
      }

      if (hasError) {
        return;
      }
    }

    if (activeStep == 2) {

      if (!stripe || !elements) {
        return;
      }

      setSubmitting(true);

      const cardElement = elements.getElement(CardElement);

      try {

        const { token } = await stripe.createToken(cardElement);

        const reqJson = {
          email: formState.email.trim(),
          isUpdate: 0,
          phoneNumber: formState.phoneNumber,
          type: 1,
          ccnumber: `************${token.card.last4}`,
          ccyear: token.card.exp_year,
          ccmonth: token.card.exp_month,
          cvc: "",
          username: `${formState.firstName} ${formState.lastName}`,
          token: token.id,
          discountCode: formState.discountCode,
          password: formState.password,
        }

        const result = await dispatch(createUser(reqJson));

        setSubmitting(false);

        if (result.type === CREATE_USER_FAILURE ) {
          const errorMessage = typeof result.errors === 'string' ? err : 'Sorry, Failed to create user. Please contact admin.';
          dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
          return;
        }
      } catch (err) {
        setSubmitting(false);
        const errorMessage = typeof err === 'string' ? err : 'Sorry, Failed to create user. Please contact admin.';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGoToWebAppLogin = () => {
    history.push('/login');
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div className={classes.formErrors}>
              {Object.keys(formErrorState).map((fieldName, i) => {
                if(formErrorState[fieldName].length > 0){
                  return (
                    <p key={i}> {formErrorState[fieldName]}</p>
                  )        
                } else {
                  return '';
                }
              })}
            </div>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoFocus
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"              
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                type="phoneNumber"
                id="phoneNumber"
                onChange={handleInputChange}
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="discountCode"
                label="Discount Code (Optional)"
                id="discountCode"
                onChange={handleInputChange}
              />
            </form>
          </div>
        );
      case 1:
        return (
          <React.Fragment>
            <Typography component="h1" variant="h5" align="center" paragraph>
              Fire Vibe Cloud Subscription
            </Typography>
            <Typography component="h1" variant="h5" align="center" paragraph>
              Free
            </Typography>
            <Typography component="h1" variant="h6" align="center">
              Features
            </Typography>
            <Typography variant="subtitle1" gutterBottom align="center">
              -Unlimited Photo Storage
            </Typography>
            <Typography variant="subtitle1" gutterBottom align="center">
              -Full Access to Pro Photo App
            </Typography>
            <Typography variant="subtitle1" gutterBottom align="center">
              -Unlimited Tech Support
            </Typography>
            <Typography variant="subtitle1" gutterBottom align="center">
              -Exclusive Access to Online Photography Classes
            </Typography>
          </React.Fragment>
        );
      case 2:
        return (
          <form>
            <Typography component="h1" variant="h5" align="center" paragraph>
              Payment Authorization
            </Typography>
            <CardElement options={options}> </CardElement>
          </form>
        );
      default:
        return 'Unknown step';
    }
  }

  return (
    <Container component="main" maxWidth='sm'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src="/assets/img/logo.png">
        </Avatar>
        <Stepper className={classes.fullWidth} activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <div className={classes.completeContainer}>
            <Typography component="h1" variant="h5" align="center"> 
              Successfully created your account. You can login with the app now.
            </Typography>
            <Button color="primary" variant="contained" onClick={handleGoToWebAppLogin}>
              Go to Webapp login
            </Button>
          </div>
        ) : (
          <div className={classes.mainContainer}>
            {getStepContent(activeStep)}
            <div className={classes.buttonContainer}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.fullWidth}>
                Back
              </Button>
              <Button disabled={submitting} className={classes.fullWidth} variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
              </Button>
            </div>
            {activeStep === (steps.length - 1) ?
            (
              <div className={classes.commentContainer}>
                <Typography component="h1" variant="h6" align="center"> 
                  Pricing
                </Typography>
                <Typography component="h1" variant="h5" align="center"> 
                  1st Project is FREE
                </Typography>
                <Typography component="h1" variant="h5" align="center"> 
                  $1.99 per submitted photo after that.
                </Typography>
                <Typography variant="subtitle1" gutterBottom align="center"> 
                  All Photos are Individually Edited by Professional Photo Editors.
                </Typography>
                <Typography variant="subtitle1" gutterBottom align="center"> 
                  A Valid Credit Card must be on file to submit a project.
                </Typography>
                <Typography variant="subtitle1" gutterBottom align="center"> 
                  You will not be charged until after we have edited the photos and sent back to you via email.
                </Typography>
              </div>
            ) : '' }
          </div>
        )}
      </div>
      
      { submitting &&
        (
          <div className={classes.loaderContainer}>
            <CircularProgress /> 
            It can takes a few minutes. Please wait.
          </div>
        )
      }
    </Container>
  );
}

export default SignUpPage;