export const FETCH_ORDERS_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_USER_FAILURE';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const FETCH_COMPLETE_ORDERS_REQUEST = 'FETCH_COMPLETE_ORDERS_REQUEST';
export const FETCH_COMPLETE_ORDERS_SUCCESS = 'FETCH_COMPLETE_ORDERS_SUCCESS';
export const FETCH_COMPLETE_ORDERS_FAILURE = 'FETCH_COMPLETE_ORDERS_FAILURE';

export const VOID_ORDER_REQUEST = 'VOID_ORDER_REQUEST';
export const VOID_ORDER_SUCCESS = 'VOID_ORDER_SUCCESS';
export const VOID_ORDER_FAILURE = 'VOID_ORDER_FAILURE';