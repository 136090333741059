import API from '../../util/api.js';

import {
  GET_ALL_USER_INFO_REQUEST,
  GET_ALL_USER_INFO_SUCCESS,
  GET_ALL_USER_INFO_FAILURE,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE
} from './constants';

const getAllUserInfoRequest = () => ({ type: GET_ALL_USER_INFO_REQUEST });
const getAllUserInfoSuccess = (payload) => ({ type: GET_ALL_USER_INFO_SUCCESS, payload });
const getAllUserInfoFailure = (errors) => ({ type: GET_ALL_USER_INFO_FAILURE, errors });

export const getAllUserInfo = () => async (dispatch) => {
  dispatch(getAllUserInfoRequest());
  try {
    const response = await API.get('/user/all', {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    });
    return dispatch(getAllUserInfoSuccess(response.data.body));
  } catch (e) {
    return dispatch(getAllUserInfoFailure(e.message));
  }
};

const updateUserInfoRequest = () => ({ type: UPDATE_USER_INFO_REQUEST });
const updateUserInfoSuccess = (payload) => ({ type: UPDATE_USER_INFO_SUCCESS, payload });
const updateUserInfoFailure = (errors) => ({ type: UPDATE_USER_INFO_FAILURE, errors });

export const updateUserInfo = (reqJSON) => async (dispatch) => {
  dispatch(updateUserInfoRequest());
  try {
    const response = await API.post('/user/updateinfo', reqJSON, {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    });
    return dispatch(updateUserInfoSuccess(response.data.body));
  } catch (e) {
    return dispatch(updateUserInfoFailure(e.message));
  }
};