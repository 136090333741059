import {
  PHOTOS_FETCHED,
  PHOTOS_RESET,
  PHOTOS_DELETE_REQUEST,
  PHOTO_UPLOAD_REQUEST,
  PHOTO_UPLOAD_PROGRESS,
  PHOTO_UPLOAD_SUCCESS,
  PHOTO_UPLOAD_FAILED,
  PHOTO_ALL_UPLOADED
} from './constants';

export const fetchPhotos = (payload) => ({ type: PHOTOS_FETCHED, payload });
export const fetchPhotosReset = () => ({type: PHOTOS_RESET})
export const fetchPhotoDeleteRequest = (index) => ({ type: PHOTOS_DELETE_REQUEST, index });

export const photoUploadRequest = (index) => ({ type: PHOTO_UPLOAD_REQUEST, index });
export const photoUploadProgress = (percents) => ({ type: PHOTO_UPLOAD_PROGRESS, percents });
export const photoUploadSuccess = (index) => ({ type: PHOTO_UPLOAD_SUCCESS, index });
export const photoUploadFailed = (index) => ({ type: PHOTO_UPLOAD_FAILED, index });
export const photoAllUploaded = (payload) => ({type: PHOTO_ALL_UPLOADED});