import {
  FETCH_MYPROJECT_REQUEST,
  FETCH_MYPROJECT_SUCCESS,
  FETCH_MYPROJECT_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE
} from './constants';

const initialState = { data: {}, loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MYPROJECT_REQUEST:
      return { ...state, loading: true };
    case FETCH_MYPROJECT_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_MYPROJECT_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CREATE_PROJECT_REQUEST:
      return { ...state, loading: true };
    case CREATE_PROJECT_SUCCESS:
      return { ...state, loading: false, errors: [] };
    case CREATE_PROJECT_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;