import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getAllUserInfo, updateUserInfo } from '../../store/alluser/actions';
import { queueNotification } from '../../store/notifications/actions';

import { GET_ALL_USER_INFO_SUCCESS, GET_ALL_USER_INFO_FAILURE, 
  UPDATE_USER_INFO_SUCCESS, UPDATE_USER_INFO_FAILURE } from '../../store/alluser/constants';

import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import LoadingButton from '../../components/LoadingButton';
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
  userTable: {
    width: '100%'
  }
}));

const CustomerPage = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({ phoneNumber: '', customerId: '', uid: '', username: '' });
  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));
  
  const [state, setState] = React.useState({
    columns: [
      { title: 'Full Name', field: 'fullname' },
      { title: 'Email', field: 'email' },
      { title: 'Phone Number', field: 'phoneNumber' },
      { title: 'Customer ID', field: 'customerId'},
      { title: 'Created At', field: 'createdAt'}
    ],
    headers: [
      { label: 'Full Name', key: 'fullname' },
      { label: 'Email', key: 'email' },
      { label: 'Phone Number', key: 'phoneNumber' },
      { label: 'Customer ID', key: 'customerId'},
      { label: 'Created At', key: 'createdAt'}
    ],
    data: [
    ],
  });
 
  useEffect(() => {
    const fetchAllUser = async () => {
      setLoading(() => true);
      const fetchAllUserResult = await dispatch(getAllUserInfo());

      setLoading(() => false);
      if (fetchAllUserResult.type === GET_ALL_USER_INFO_FAILURE) {
        const errorMessage = typeof fetchAllUserResult.errors === 'string' ? err : 'Failed to fetch all the users.';
        dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
      }

      if (fetchAllUserResult.type === GET_ALL_USER_INFO_SUCCESS) {
        setState((prevState) => {
          const data = [];
          for (var key in fetchAllUserResult.payload) {
            if (fetchAllUserResult.payload.hasOwnProperty(key)) {
              data.push({
                fullname: fetchAllUserResult.payload[key].username, 
                email: fetchAllUserResult.payload[key].email, 
                phoneNumber: fetchAllUserResult.payload[key].phoneNumber,
                customerId: (fetchAllUserResult.payload[key].customer_id === undefined || fetchAllUserResult.payload[key].customer_id === "") ? "" : fetchAllUserResult.payload[key].customer_id,
                createdAt: fetchAllUserResult.payload[key].createDate,
                uid: fetchAllUserResult.payload[key].uid
              })
            }
          }
          return { ...prevState, data };
        });
      }
    }

    fetchAllUser()
  }, []);

  const handleRowClick = (event, rowData) => {

    setFormState({
      uid: rowData.uid,
      phoneNumber: rowData.phoneNumber,
      customerId: rowData.customerId,
      username: rowData.fullname,
    });

    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdate = useCallback(async () => {

    if (formState.phoneNumber.trim().length == 0) {
      dispatch(queueNotification({ type: 'ERROR', message: 'Please add Phone Number' }));
      return
    }

    // if (formState.customerId.trim().length == 0) {
    //   dispatch(queueNotification({ type: 'ERROR', message: 'Please add Customer ID' }));
    //   return;
    // }

    setSubmitting(true);

    const reqJSON = {
      uid: formState.uid,
      phoneNumber: formState.phoneNumber,
      customer_id: formState.customerId,
      username: formState.username
    }

    const result = await dispatch(updateUserInfo(reqJSON));

    setSubmitting(false);
    setOpen(false)

    if (result.type === UPDATE_USER_INFO_SUCCESS) {
      dispatch(queueNotification({ type: 'SUCCESS', message: 'Updated succesfully' }));
    } 
    
    if (result.type === UPDATE_USER_INFO_FAILURE) {
      const errorMessage =
        typeof result.errors === 'string' ? result.errors : 'Failed to update user information';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }
  });

  return (
    <div className={classes.root}>
      <div className={classes.placeholder}>
        { loading && <CircularProgress /> }
      </div>
      
      { !loading && (
        <div className={classes.userTable}>
          <MaterialTable
            className={classes.userTable}
            title="User Table"
            columns={state.columns}
            data={state.data}
            onRowClick={handleRowClick}
          />
          <CSVLink headers={state.headers} data={state.data} filename={"users.csv"}>Download Users</CSVLink>
        </div>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Full Name"
            defaultValue={formState.username}
            onChange={handleInputChange}
            fullWidth
          />

          <TextField
            autoFocus
            margin="dense"
            name="phoneNumber"
            label="Phone Number"
            defaultValue={formState.phoneNumber}
            onChange={handleInputChange}
            fullWidth
          />

          <TextField
            autoFocus
            margin="dense"
            name="customerId"
            label="Customer ID"
            defaultValue={formState.customerId}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton onClick={handleUpdate} color="primary" loading={submitting} disabled={submitting}>
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CustomerPage;
