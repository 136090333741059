import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import NotificationCenter from '../../NotificationCenter';

const PublicLayout = ({ children }) => {

  useEffect(() => {
  }, []);

  return (
    <div>
      {children}
      <NotificationCenter />
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired
};

export default PublicLayout;
