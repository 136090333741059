import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Chart } from "react-google-charts";
import { makeStyles } from '@material-ui/core/styles';

import { getDashboard } from '../../store/dashboard/actions';
import { GET_DASHBOARD_FAILURE, GET_DASHBOARD_SUCCESS } from '../../store/dashboard/constants';

import { queueNotification } from '../../store/notifications/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  cardContent: {
    marginBottom: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
  chartHeight: {
    height: 240,
    padding: theme.spacing(3)
  },
}));

const Dashboard = () => {
 
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dashboard = useSelector((state) => state.dashboard);

  const [state, setState] = React.useState({
    data: [],
  });
  
  useEffect(() => {
    const fetchDashboard = async () => {
      setLoading(() => true);
      const fetchDashboardResult = await dispatch(getDashboard());

      setLoading(() => false);
      if (fetchDashboardResult.type === GET_DASHBOARD_FAILURE) {
        dispatch(queueNotification({ type: 'ERROR', message: fetchOrderResult.errors }));
      }

      if (fetchDashboardResult.type === GET_DASHBOARD_SUCCESS) {
        setState((prevState) => {
          const data = [];
          data.push(['Date', 'Projects', 'Photo Count', 'Video Count']);
          Object.keys(fetchDashboardResult.payload.chart).forEach(function(key){
            const row = fetchDashboardResult.payload.chart[key];
            data.push([key, row.projectCount, row.photoCount, row.videoCount]);
          });
          return { ...prevState, data };
        })
      }
    }

    fetchDashboard()
  }, []);

  const renderChart = () => {
    return (
      <Chart
        className={classes.chartHeight}
        width={'100%'}
        height={'100%'}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={state.data}
        options={{
          chart: {
            title: 'Company Performance',
            subtitle: 'Project Counts',
          },
        }}
      />
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.placeholder}>
        { loading && <CircularProgress /> }
      </div>
      {
        !loading && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={7} lg={8}>
              <Paper>
                {renderChart()}
              </Paper>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <Card className={classes.cardContent}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Users
                  </Typography>
                  <Typography variant="body2" component="p">
                    {dashboard.data.usersCount}
                  </Typography>
                </CardContent>
              </Card>
              <Card className={classes.cardContent}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Today Project Counts
                  </Typography>
                  <Typography variant="body2" component="p">
                    {dashboard.data.todayProjectCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )
      }
    </div>
  );
};
export default Dashboard;
