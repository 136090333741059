import { firevibe } from '../../firebase/firebase';
import API from '../../util/api.js';

import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  CHECK_ADMIN_REQUEST,
  CHECK_ADMIN_SUCCESS,
  CHECK_ADMIN_FAILURE,
  CHECK_NOADMIN_SUCCESS,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
} from './constants';

const fetchUserRequest = () => ({ type: FETCH_USER_REQUEST });
const fetchUserSuccess = (payload) => ({ type: FETCH_USER_SUCCESS, payload });
const fetchUserFailure = (errors) => ({ type: FETCH_USER_FAILURE, errors });

export const fetchUser = (email, password) => async (dispatch) => {
  dispatch(fetchUserRequest());
  try {
    const response = await firevibe.auth().signInWithEmailAndPassword(email, password)
    return dispatch(fetchUserSuccess(response));
  } catch (e) {
    return dispatch(fetchUserFailure(e.message));
  }
};

const createUserRequest = () => ({ type: CREATE_USER_REQUEST });
const createUserSuccess = (payload) => ({ type: CREATE_USER_SUCCESS, payload });
const createUserFailure = (errors) => ({ type: CREATE_USER_FAILURE, errors });

export const createUser = (user) => async (dispatch) => {
  dispatch(createUserRequest());
  try {
    const response = await API.post('/user/create', user)
    return dispatch(createUserSuccess(response));
  } catch (e) {
    return dispatch(createUserFailure(e.message));
  }
};

const checkAdminRequest = () => ({ type: CHECK_ADMIN_REQUEST });
const checkNoAdminSuccess = () => ({ type: CHECK_NOADMIN_SUCCESS });
const checkAdminSuccess = () => ({ type: CHECK_ADMIN_SUCCESS });
const checkAdminFailure = (errors) => ({ type: CHECK_ADMIN_FAILURE, errors });

export const checkAdmin = () => async (dispatch) => {
  dispatch(checkAdminRequest());
  try {
    const isAdmin = await API.get('/user/isAdmin', {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    })
    if (isAdmin.data.body === "admin") {
      return dispatch(checkAdminSuccess());
    } else {
      return dispatch(checkNoAdminSuccess());
    }
  } catch (e) {
    return dispatch(checkAdminFailure(e.message));
  }
};

const getUserInfoRequest = () => ({ type: GET_USER_INFO_REQUEST });
const getUserInfoSuccess = (payload) => ({ type: GET_USER_INFO_SUCCESS, payload });
const getUserInfoFailure = (errors) => ({ type: GET_USER_INFO_FAILURE, errors });

export const getUserInfo = () => async (dispatch) => {
  dispatch(getUserInfoRequest());
  try {
    const response = await API.get('/user', {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    });
    return dispatch(getUserInfoSuccess(response.data.body));
  } catch (e) {
    return dispatch(getUserInfoFailure(e.message));
  }
};

const updateUserInfoRequest = () => ({ type: UPDATE_USER_INFO_REQUEST });
const updateUserInfoSuccess = (payload) => ({ type: UPDATE_USER_INFO_SUCCESS, payload });
const updateUserInfoFailure = (errors) => ({ type: UPDATE_USER_INFO_FAILURE, errors });

export const updateUserInfo = (userInfo) => async (dispatch) => {
  dispatch(updateUserInfoRequest());
  try {
    const response = await API.post('/user/update', userInfo, {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    });
    return dispatch(updateUserInfoSuccess(response.data.body));
  } catch (e) {
    return dispatch(updateUserInfoFailure(e.message));
  }
};