import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'react-moment';
import moment from 'moment';

import EnhancedTableHead from './../Table/EnhancedTableHead.js';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { fetchOrders, updateOrder, voidOrder } from '../../store/orders/actions';
import { queueNotification } from '../../store/notifications/actions';

import { FETCH_ORDERS_FAILURE, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE,
  VOID_ORDER_SUCCESS, VOID_ORDER_FAILURE } from '../../store/orders/constants';

import LoadingButton from '../../components/LoadingButton';

import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    height: 40,
  },
  buttonLeftMargin: {
    marginLeft: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    padding: '0px',

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const headers = [
  { label: 'Project Name', key: 'projectName' },
  { label: 'Email', key: 'emailAddress' },
  { label: 'Photo Count', key: 'photoCount' },
  { label: 'video Count', key: 'videoCount'},
  { label: 'DiscountCode', key: 'discountCode'},
  { label: 'Price', key: 'price'},
  { label: 'Created At', key: 'createdAt'},
];

const headCells = [
  { id: 'no', numeric: true, disablePadding: true, label: 'No' },
  { id: 'projectName', numeric: false, disablePadding: true, label: 'Project Name' },
  { id: 'emailAddress', numeric: false, disablePadding: false, label: 'Email Address' },
  { id: 'photoCount', numeric: true, disablePadding: false, label: 'Photo Count' },
  { id: 'videoCount', numeric: true, disablePadding: false, label: 'Video Count' },
  { id: 'discountCode', numeric: false, disablePadding: false, label: 'DiscountCode' },
  { id: 'price', numeric: false, disablePadding: false, label: 'Price' },
  { id: 'cratedAt', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
];

const OpenOrderPage = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('no');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [formState, setFormState] = useState(
    { 
      dropboxURL: '', 
      userId: '', 
      id: '', 
      emailAddress: '', 
      projectName: '', 
      phoneNumber: '',
      amount: 0,
      stripeId: '',
      photoCount: 0,
      videoCount: 0,
      description: '',
      discountCode: ''
    }
  );
  const handleInputChange = useCallback((e) => setFormState({ ...formState, [e.target.name]: e.target.value }));

  const orders = useSelector((state) => state.orders);

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(() => true);
      const fetchOrderResult = await dispatch(fetchOrders());

      setLoading(() => false);
      if (fetchOrderResult.type === FETCH_ORDERS_FAILURE) {
        dispatch(queueNotification({ type: 'ERROR', message: fetchOrderResult.errors }));
      }
    }

    fetchOrder()
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, Object.keys(orders?.data).length - page * rowsPerPage);

  const onDeliveryProject = (project) => {

    const amount = project.discountCode !== "FIREVIBE20" ? parseFloat(project.photoCount) * 199 : parseFloat(project.photoCount) * 159; 

    setFormState({
      userId: project.userId, 
      id: project.id, 
      emailAddress: project.emailAddress, 
      dropboxURL: '', 
      projectName: project.projectName,
      photoCount: project.photoCount,
      videoCount: project.videoCount,
      amount: amount / 100,
      phoneNumber: project.phoneNumber,
      stripeId: project.stripeId,
      discountCode: project.discountCode
    });

    setOpen(true)
  }

  const onVoidProject = useCallback(async (project) => {
    
    setSubmitting(true);

    var reqJSON = {
      userId: project.userId,
      id: project.id,
      dropboxURL: 'VOIDPROJECT',
      emailAddress: project.emailAddress,
      projectName: project.projectName,
    }

    const result = await dispatch(voidOrder(reqJSON));

    if (result.type === VOID_ORDER_SUCCESS) {
      dispatch(queueNotification({ type: 'SUCCESS', message: result.payload.msg }));
    } 
    
    if (result.type === VOID_ORDER_FAILURE) {
      const errorMessage =
        typeof result.errors === 'string' ? result.errors : 'Failed to void project';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }

    setSubmitting(false);

  })

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdate = useCallback(async () => {
    if (formState.dropboxURL.trim().length == 0) {
      dispatch(queueNotification({ type: 'ERROR', message: 'Please add Dropbox Link' }));
      return
    }
    
    setSubmitting(true);

    var reqJSON = formState;
    reqJSON.amount = formState.amount;
    reqJSON.description = `${formState.photoCount} Photos @ ${formState.discountCode !== "FIREVIBE20" ? "$1.99/Photo" : "$1.59/Photo"} for ${formState.projectName}`;
    
    const result = await dispatch(updateOrder(reqJSON));
    
    setSubmitting(false);
    setOpen(false)
    if (result.type === UPDATE_ORDER_SUCCESS) {
      dispatch(queueNotification({ type: 'SUCCESS', message: result.payload.msg }));
    } 
    
    if (result.type === UPDATE_ORDER_FAILURE) {
      const errorMessage =
        typeof result.errors === 'string' ? result.errors : 'Failed to delivery project';
      dispatch(queueNotification({ type: 'ERROR', message: errorMessage }));
    }
  })

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.data.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  
  }

  var rows = [];

  if (Object.keys(orders?.data).length > 0) {

    orders?.data.map((order) => {
      rows.push({
        projectName: order.projectName,
        emailAddress: order.emailAddress,
        photoCount: order.photoCount,
        videoCount: order.videoCount,
        discountCode: order.discountCode,
        price: order.discountCode !== "FIREVIBE20" ? '$'.concat(1.99 * order.photoCount) : '$'.concat(1.59 * order.photoCount),
        createdAt: moment.unix(order.createdAt._seconds).format('YYYY/MM/DD'),
      });
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.placeholder}>
      { loading && <CircularProgress /> }
      </div>
      { Object.keys(orders?.data).length > 0 ? (
        <React.Fragment>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="Open Order"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(orders, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <StyledTableRow
                        hover
                        tabIndex={-1}
                        key={index}
                      >
                        <StyledTableCell component="th" scope="row" padding="none">{index+1}</StyledTableCell>
                        <StyledTableCell align="center">{row.projectName}</StyledTableCell>
                        <StyledTableCell align="center">{row.emailAddress}</StyledTableCell>
                        <StyledTableCell align="center">{row.photoCount}</StyledTableCell>
                        <StyledTableCell align="center">{row.videoCount}</StyledTableCell>
                        <StyledTableCell align="center">{row.discountCode}</StyledTableCell>
                        <StyledTableCell align="center">{row.discountCode !== "FIREVIBE20" ? '$'.concat(1.99 * row.photoCount) : '$'.concat(1.59 * row.photoCount)}</StyledTableCell>
                        <StyledTableCell align="center"><Moment unix format="YYYY/MM/DD">{row.createdAt._seconds}</Moment></StyledTableCell>
                        <StyledTableCell align="center">
                          <Button disabled={submitting} variant="contained" color="primary" onClick={() => onDeliveryProject(row)}> Delivery </Button>
                          <Button disabled={submitting} className={classes.buttonLeftMargin} variant="contained" color="secondary" onClick={() => onVoidProject(row)}> Void </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={Object.keys(orders?.data).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <CSVLink headers={headers} data={rows} filename={"open-orders.csv"}>Download Open Orders</CSVLink>
        </React.Fragment>
      ) : 'No Orders'}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle id="form-dialog-title">Delivery</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                name="projectName"
                label="Project Name"
                value={formState.projectName}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                name="emailAddress"
                value={formState.emailAddress}
                label="Email Address"
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                name="phoneNumber"
                label="Phone Number"
                value={formState.phoneNumber}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                name="stripeId"
                value={formState.stripeId}
                label="Stripe ID"
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                name="photoCount"
                label="Photo Count"
                value={formState.photoCount}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                name="videoCount"
                value={formState.videoCount}
                label="Video Count"
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                name="amount"
                label="Price ($)"
                value={formState.amount}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                name="dropboxURL"
                label="Dropbox Link"
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton onClick={handleUpdate} color="primary" loading={submitting} disabled={submitting}>
            Send Photos
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default OpenOrderPage;
