import { firevibe } from './../firebase/firebase';

const getUser = () =>  {

  return new Promise(function (resolve, reject) {
    firevibe.auth().onAuthStateChanged(function (user) {
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
    });
  });
};

export default getUser;