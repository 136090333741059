import { FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILURE,
  UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE,
  FETCH_COMPLETE_ORDERS_REQUEST, FETCH_COMPLETE_ORDERS_SUCCESS, FETCH_COMPLETE_ORDERS_FAILURE,
  VOID_ORDER_REQUEST, VOID_ORDER_SUCCESS, VOID_ORDER_FAILURE } from './constants';

const initialState = { data: {}, loading: false, errors: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      return { ...state, loading: true };
    case FETCH_ORDERS_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_ORDERS_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case UPDATE_ORDER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_ORDER_SUCCESS:
      var updatedOrders = state.data.filter( (element, index) => {
        return element.id != action.payload.id;
      })
      return { ...state, loading: false, data: updatedOrders, errors: [] };
    case UPDATE_ORDER_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case VOID_ORDER_REQUEST:
      return { ...state, loading: true };
    case VOID_ORDER_SUCCESS:
      updatedOrders = state.data.filter( (element, index) => {
        return element.id != action.payload.id;
      })
      return { ...state, loading: false, data: updatedOrders, errors: [] };
    case VOID_ORDER_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case FETCH_COMPLETE_ORDERS_REQUEST:
      return { ...state, loading: true };
    case FETCH_COMPLETE_ORDERS_SUCCESS:
      return { ...state, loading: false, errors: [] };
    case FETCH_COMPLETE_ORDERS_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;