import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  CircularProgressContainer: {
    marginLeft: theme.spacing(2),
    display: 'flex',
  }
}))

const LoadingButton = (props) => {

  const classes = useStyles();

  const {
    children,
    loading,
    ...rest
  } = props
  return (
    <Button {...rest}>
      {children}
      {loading && <div className={classes.CircularProgressContainer}> <CircularProgress size={20} color="inherit" /> </div> }
    </Button>
  )
}

export default LoadingButton;